import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RuleCategory, RulesModel } from '@/store/api/rulesApi/rulesModels.ts';
import { setRequestHeaders } from '@/web/utils/webApi.ts';
import { getConfig } from '@/utils/config/config.ts';

export const rulesApi = createApi({
  reducerPath: 'rulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig().apiUrl}`,
    prepareHeaders: headers => {
      return setRequestHeaders(headers);
    },
  }),
  tagTypes: ['rule'],
  endpoints: builder => ({
    getRulesByCategory: builder.query<RulesModel[], RuleCategory>({
      query: category => ({
        url: '/api/rules/grid-rules/category',
        params: {
          asOfDate: new Date().toISOString(),
          context: 'MARK',
          category,
        },
      }),
      providesTags: (_result, _error, arg) => [{ type: 'rule', id: arg }],
    }),
    updateRule: builder.mutation<RulesModel, RulesModel>({
      query: rule => ({
        url: `api/rules/grid-rules/update/rule/${rule.id}/version/${rule.version}`,
        method: 'PUT',
        body: rule,
      }),
      invalidatesTags: result => {
        if (result === undefined) {
          return [];
        }
        return [{ type: 'rule', id: result.category }];
      },
    }),
  }),
});

export const { useGetRulesByCategoryQuery, useUpdateRuleMutation } = rulesApi;
